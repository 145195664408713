import React from 'react'
import ReactDOM from 'react-dom';
import "./AboutPage.css"

class AboutPage extends React.Component {
    render() {
      return (
        <div className="aboutPageWrapper">
          <div className="aboutTextWrapper">
            <h1 className="aboutHeaders">About Maitri</h1>
            <p className="abouttext">What makes an all natural luxury candle is the craftsmanship, detail, and high quality ingredients. These candles are all natural coconut-soy wax blends with only pure essential oils. This allows you to reap the holistic benefits the candles have to offer through their aromas. So not only do they smell great but they are good for the mind and body too.</p>
            <h1 className="aboutHeaders">About Me</h1>
            <p className="abouttext">I used to collect candles and now my interest in it has also motivated me to start making them. I like how whatever mood I or my guests are in, I could just light up a candle to match the mood. Even though Maitri is a business, I'm passion driven and do it because I really enjoy the process of creating and sharing what I love.</p>
          </div>
        </div>
      )

      
    }
}

export default AboutPage