import React from 'react'
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import NavBar from '../../components/NavBar/NavBar'
import HorizontalLogo from '../../pictures/assets/horizontalLogo.png'
import './Header.css'

class Header extends React.Component {
    render() {
      return (
        <div className="HeaderWrap">
            <button><Link to="/"><img className="horizontalLogo" src={HorizontalLogo}></img></Link></button>
            <NavBar />
        </div>
      )
    }
}

export default Header