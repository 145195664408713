import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import AboutPage from '../../containers/AboutPage/AboutPage'
import OrderPage from '../../containers/OrderPage/OrderPage'
import ProductsPage from '../../containers/ProductsPage/ProductsPage'
import HomePage from '../../containers/HomePage/HomePage'
import './NavBar.css'

class NavBar extends React.Component {
    render() {
      return (
          <div className="navBarWrapper">
            <div className="navLinks">
                <h3><Link className="links" to="/">home</Link></h3>
                <h3><Link className="links link1" to="/about">about</Link></h3>
                <h3><Link className="links link1" to="/products">products</Link></h3>
                <h3><Link className="links link1" to="/ordering">ordering</Link></h3>
            </div>
          </div>
      )
    }
}

export default NavBar