import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {StripeProvider} from 'react-stripe-elements';
//import logo from './logo.svg';
import Header from './containers/Header/Header.js'
import Footer from './containers/Footer/Footer.js'
import NavBar from './components/NavBar/NavBar.js'
import AboutPage from './containers/AboutPage/AboutPage'
import OrderPage from './containers/OrderPage/OrderPage'
import ProductsPage from './containers/ProductsPage/ProductsPage'
import HomePage from './containers/HomePage/HomePage'
import WebsiteInfo from './containers/WebsiteInfo/WebsiteInfo'
import './App.css';

class App extends Component {
  render() {
    return (
      <StripeProvider apiKey="pk_test_SlW8SEBNifQIxqUNgNmaS5NK">
        <div className="App">
          <Header />
          <div className="contentWrapper">
            <Route exact path="/" component={HomePage} />
            <Route path="/about" component={AboutPage} />
            <Route path="/products" component={ProductsPage} />
            <Route path="/ordering" component={OrderPage} />
            <Route path="/siteinfo" component={WebsiteInfo} />
          </div>
          <Footer />
        </div>
      </StripeProvider>

    )
  }
}

export default App;
