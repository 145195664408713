import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Link } from "react-router-dom";
import './Footer.css'
import SiteLogo from '../../pictures/assets/sitelogo.png'
import EmailLogo from '../../pictures/assets/emaillogo.png'
import EtsyLogo from '../../pictures/assets/etsylogo.png'

class Footer extends React.Component {
    render() {
      return (
        <div className="footerWrapper">
          <section className="contentFooter">
            <div className="footerLine" id="store"><a href="https://www.etsy.com/shop/LiveMaitri">ETSY STORE</a></div>
            <div className="footerLine" id="instagram"><a href="https://www.instagram.com/live_maitri/">INSTAGRAM</a></div>
            <div className="footerLine" id="email"><a>MAITRICOMPANY@GMAIL.COM</a></div>
            <div className="footerLine" id="pinterest"><a href="https://www.pinterest.com/LiveMaitriCo/">PINTEREST</a></div>
            <div className="footerLine" id="site"><a href="/siteinfo">ABOUT WEBSITE</a></div>
          </section>
        </div>
      )
    }
}

export default Footer