import React from 'react'
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';

export default class Checkout extends React.Component {
    constructor(props) {
        super(props)
        this.state={
        }
    }
  onToken = (token) => {
    // TODO: Send the token information and any other
    // relevant information to your payment process
    // server, wait for the response, and update the UI
    // accordingly. How this is done is up to you. Using
    // XHR, fetch, or a GraphQL mutation is typical.

    axios({
        method: "POST", 
        url:`${process.env.REACT_APP_BACKEND}/checkout`,
        date: {
            amount: "500",
            billingAddress: "Fake Address",
            description: "Test Charge",
            image: "https://stripe.com/img/documentation/checkout/marketplace.png",
            locale: "auto",
            name: "Maitri",
            zipCode: "00000"
        },
        headers: {
            'Content-Type': 'application/json'
        }
    })

  };

  render() {
    return (
      <StripeCheckout
        amount="500"
        billingAddress="Fake Address"
        description="Test Charge"
        image="https://stripe.com/img/documentation/checkout/marketplace.png"
        locale="auto"
        name="Maitri"
        stripeKey="pk_test_SlW8SEBNifQIxqUNgNmaS5NK"
        token={this.onToken}
        zipCode="00000"
        label="Pay Now?"
      />
    )
  }
}