import React from 'react'
import ReactDOM from 'react-dom'
import CandleDisplay from '../../components/CandleDisplay/CandleDisplay.js'
import Lipbalmdisplay from '../../components/Lipbalmdisplay/Lipbalmdisplay.js'
import scrollToComponent from 'react-scroll-to-component';
import './ProductsPage.css'


class ProductsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
    render() {
      var products = [
        {
          productName: "test1",
          description: "test1 description",
          image: "http://www.pbh2.com/wordpress/wp-content/uploads/2013/01/cutest-corgi-pictures-fluffy-butt.jpg"
        },
        {
          productName: "test2",
          description: "test2 description",
          image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQUhyPinlVFSuQy7ArNYdbO6GGUC6utlwMB_OFD_VmznbDs7iysA"
        },
        {
          productName: "test3",
          description: "test3 description",
          image: "https://assets.bhg.com/bhg/styles/nfp_1080_portrait/public/images/2017/3/15/102997431.jpg?8a7BH6ixKOPbYWfnWgBgiPbzb1SgOLfo"
        }
      ]
      var productCard = products.map(function(item, key){
        if(key%2 != 0) {
            return (
              <div className="oddProductCard" id={key}>
                <div className="oddProductText">
                  <h3>{item.productName}</h3>
                  <h4>{item.description}</h4>
                </div>
                <div className="oddImageCont">
                </div>
                <img src={item.image}/>
              </div>
            )
        } else {
            return (
              <div className="evenProductCard" id={key}>
                <div className="evenProductText">
                  <h3>{item.productName}</h3>
                  <h4>{item.description}</h4>
                </div>
                <div className="evenImageCont">
                  <h1></h1>
                </div>
                  <img src={item.image}/>
              </div>
            )
        }
      })

      //<div className="productsCardWrapper">
      //{productCard}
      //</div>
      return (
        <div className="productsPageWrapper">
          <div className="productPageHeader">
            <h1 id="productheader1">Hand Poured in Micro Batches</h1>
          </div>
          <div class="productLinks">
            <button className="productlinkbuttons" onClick={() => scrollToComponent(this.Candles, { offset: 0, align: 'top', duration: 1000})}>Go To Candles</button>
            <button className="productlinkbuttons" onClick={() => scrollToComponent(this.Lipbalms, { offset: 0, align: 'top', duration: 1000})}>Go To Lipbalms</button>
          </div>
          <section className='candles' ref={(section) => { this.Candles = section; }}><CandleDisplay /></section>
          <section className='lipbalms' ref={(section) => { this.Lipbalms = section; }}><Lipbalmdisplay /></section>
        </div>
      )
    }
}

export default ProductsPage