import React from 'react'
import ReactDOM from 'react-dom'
import "./Slider.css"

class Slider extends React.Component {
    constructor(props) {
        super(props)

        this.state={
            "counter": 0,
            "currentImage": "https://www.holistapet.com/wp-content/uploads/2018/03/endocannabinoid-system-in-dogs-happy-dog-cbd.jpg",
            "imagePosition": ""
        }

        this.nextSlide = this.nextSlide.bind(this)
    }

    nextSlide() {
        console.log("nextSlide clicked")
        var sliderImages = [
            "https://www.holistapet.com/wp-content/uploads/2018/03/endocannabinoid-system-in-dogs-happy-dog-cbd.jpg",
            "https://tahoemountainlodging.com/wp-content/uploads/LakeTahoe-4.jpg",
            "https://www.gannett-cdn.com/presto/2018/12/22/USAT/ce06ba8e-b60b-4306-8bdf-c12e5e5f3a80-GettyImages-1060622190.jpg?width=534&height=401&fit=bounds&auto=webp"
        ]
        console.log("length: "+ (sliderImages.length - 1) )
        if(this.state.counter >= (sliderImages.length-1)) {
            console.log("counter reset")
            this.setState({
                "imagePosition": "image-leave",
                "counter": 0,
                "currentImage": sliderImages[0]
            })
        } else {
            this.setState({
                "imagePosition": "image-leave",
                "counter": this.state.counter += 1,
                "currentImage": sliderImages[this.state.counter]
            })
        }
        
    }

    render() {
    console.log("counter: "+ this.state.counter)
    console.log("current image: "+this.state.currentImage)
    console.log("postion: "+this.state.imagePosition)
      return (
            <div className="sliderWrapper">
                <div className="sliderImages">
                    <img className={this.state.imagePosition} src={this.state.currentImage}/>
                    <svg className="downArrow" onClick={this.nextSlide} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 12l-4-4h8l-4 4z"/></svg>
                </div>
            </div>
      )
    }
}

export default Slider