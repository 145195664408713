import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  async submit(ev) {
    // User clicked submit
    var {token} = await this.props.stripe.createToken({name: "Name"});
    console.log("token: " + token)
    /*
    let response = await fetch(`${process.env.REACT_APP_BACKEND}/checkout`, {
      method: "POST",
      headers: {"Content-Type": "text/plain"},
      body: token.id
    });
  
    if (response.ok) console.log("Purchase Complete!")*/
  }

  render() {
    return (
      <div className="checkout">
      <form action="your-server-side-code" method="POST">
        <script
            src="https://checkout.stripe.com/checkout.js" class="stripe-button"
            data-key="pk_test_SlW8SEBNifQIxqUNgNmaS5NK"
            data-amount="999"
            data-name="Maitri"
            data-description="Example charge"
            data-image="https://stripe.com/img/documentation/checkout/marketplace.png"
            data-locale="auto">
        </script>
        </form>
        <p>Would you like to complete the purchase?</p>
        <CardElement />
        <button onClick={this.submit}>Send</button>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);