import React from 'react'
import ReactDom from 'react-dom'
import './PictureMarketing.css'
import video from '../../pictures/assets/candlestitleplay1.mp4'

class PictureMarketing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
        //this.playVideo = this.playVideo.bind(this)
    }

    componentWillMount() {
 
    }

    render() {
        return (
            <div className="pictureMarketingWrapper">
                <div className="typewriter">
                    <h1>all natural coconut soy blend</h1>
                    <h1>essential oil aromatherapy</h1>
                    <h1>candles made with love ♡</h1>
                </div>
            </div>
        )
    }
}

export default PictureMarketing