import React from 'react'
import ReactDom from 'react-dom'
import './CandleDisplay.css'

//CANDLE IMAGES FOR GRID
import JarCandles from '../../pictures/assets/jarcandles_artsy.jpg'
import KintoTop from '../../pictures/assets/kinto_top_artsy.jpg'
import LitKinto from '../../pictures/assets/lit_kinto.jpg'
import BothStyles from '../../pictures/assets/bothstyles_artsy.jpg'
//BUTTONS
import AmazenButton from '../../pictures/assets/amazen_button.png'
import FlowerDazeButton from '../../pictures/assets/flowerdaze_button.png'
import JazzButton from '../../pictures/assets/jazz_button.png'
import EnchantedButton from '../../pictures/assets/enchanted_button.png'
import LatteButton from '../../pictures/assets/lattebutton.png'
import GardenButton from '../../pictures/assets/garden_button.png'
//PRODUCT PICS
import amazenjar from '../../pictures/assets/amazenartsy.jpg'
import amazenglass from '../../pictures/assets/amazenglassartsy.jpg'
import enchantedjar from '../../pictures/assets/enchantedartsy.jpg'
import enchantedglass from '../../pictures/assets/enchanted_kinto_top.jpg'
import flowerdazejar from '../../pictures/assets/flowerdazeartsy.jpg'
import flowerdazeglass from '../../pictures/assets/flowerglassartsy.jpg'
import jazzjar from '../../pictures/assets/jazzartsy.jpg'
import jazzglass from '../../pictures/assets/jazzkintoside.jpg'
import lattejar from '../../pictures/assets/latte_standup.jpg'
import latteglass from '../../pictures/assets/latte_glass_top.jpg'
import gardenjar from '../../pictures/assets/gardenZ_standup.jpg'
import gardenglass from '../../pictures/assets/gardenZ_glass.jpg'

var candleData = {
    candle: [
        {
            name: "Afternoon Latte Candle",
            scentfamily : "Woody & Gourmand",
            description: "A relaxing afternoon outside with a warm latte in your hands. This candle features light scents of woodiness, coffee, and a bit of cinnamon",
            ingredients: "texas cedarwood, coffee, benzoin, cinnamon leaf essential oils",
            jarimagesrc: lattejar,
            glassimagesrc: latteglass
        },
        {
            name: "AmaZen Candle",
            scentfamily : "Citrusy & Woody",
            description: "A relaxation and meditation candle that is good for yoga, easing stress and tension",
            ingredients: "frankincense, palmarosa, bergamot, juniper berry essential oils, coconut/soy wax",
            jarimagesrc: amazenjar,
            glassimagesrc: amazenglass
        },
        {
            name: "Enchanted Florist Candle",
            scentfamily : "Floral & Woody",
            description: "Lavender and light sweet woody scents can make for a magically calming scent. Daydream and relax with this beautiful candle",
            ingredients: "lavender, atlas cedarwood, cypress essential oils, coconut/soy wax",
            jarimagesrc: enchantedjar,
            glassimagesrc: enchantedglass
        },
        {
            name: "Flower Daze Candle",
            scentfamily : "Fresh & Floral",
            description: "Imagine relaxing in grassy fields with a nice breeze and the sun beaming. Sometimes it's nice to stop and smell the flowers",
            ingredients: "roman chamomile, lavender, atlas cedarwood, geranium rose, geranium bourbon essential oils, coconut/soy wax",
            jarimagesrc: flowerdazejar,
            glassimagesrc: flowerdazeglass
        },
        {
            name: "Garden Zest Candle",
            scentfamily : "Green & Citrusy",
            description: "A tart & herbaceous scent balanced with some earthiness is reminiscent of spending time in a beautiful garden on a sunny day",
            ingredients: "cilantro, blood orange, manuka, and patchouli essential oils, coconut/soy wax",
            jarimagesrc: gardenjar,
            glassimagesrc: gardenglass
        },
        {
            name: "Jazz at the Speakeasy Candle",
            scentfamily : "Woody & Citrusy",
            description: "Cocktails and jazz in a dim candle lit space with hints of smoke and citrus",
            ingredients: "orange bitters, sweet orange, bergamot, texas cedarwood, benzoin, vetiver essential oils, coconut/soy wax",
            jarimagesrc: jazzjar,
            glassimagesrc: jazzglass
        }
    ]
  };

  
class CandleDisplay extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            candlename: candleData.candle[0].name,
            scentfamily: candleData.candle[0].scentfamily,
            description: candleData.candle[0].description,
            ingredients: candleData.candle[0].ingredients,
            jarimagesrc: candleData.candle[0].jarimagesrc,
            glassimagesrc: candleData.candle[0].glassimagesrc
        }
        this.selectCandle = this.selectCandle.bind(this)
    }

    componentWillMount() {
 
    }

    selectCandle(e) {
        var target = e.target.id
        if(target == 'LatteButton') {
            this.setState({
                candlename: candleData.candle[0].name,
                scentfamily: candleData.candle[0].scentfamily,
                description: candleData.candle[0].description,
                ingredients: candleData.candle[0].ingredients,
                jarimagesrc: candleData.candle[0].jarimagesrc,
                glassimagesrc: candleData.candle[0].glassimagesrc
            })
        }
        else if(target == 'AmazenButton') {
            this.setState({
                candlename: candleData.candle[1].name,
                scentfamily: candleData.candle[1].scentfamily,
                description: candleData.candle[1].description,
                ingredients: candleData.candle[1].ingredients,
                jarimagesrc: candleData.candle[1].jarimagesrc,
                glassimagesrc: candleData.candle[1].glassimagesrc
            })
        }
        else if(target == 'EnchantedButton') {
            this.setState({
                candlename: candleData.candle[2].name,
                scentfamily: candleData.candle[2].scentfamily,
                description: candleData.candle[2].description,
                ingredients: candleData.candle[2].ingredients,
                jarimagesrc: candleData.candle[2].jarimagesrc,
                glassimagesrc: candleData.candle[2].glassimagesrc
            })
        }
        else if(target == 'FlowerDazeButton') {
            this.setState({
                candlename: candleData.candle[3].name,
                scentfamily: candleData.candle[3].scentfamily,
                description: candleData.candle[3].description,
                ingredients: candleData.candle[3].ingredients,
                jarimagesrc: candleData.candle[3].jarimagesrc,
                glassimagesrc: candleData.candle[3].glassimagesrc
            })
        }
        else if(target == 'GardenButton') {
            this.setState({
                candlename: candleData.candle[4].name,
                scentfamily: candleData.candle[4].scentfamily,
                description: candleData.candle[4].description,
                ingredients: candleData.candle[4].ingredients,
                jarimagesrc: candleData.candle[4].jarimagesrc,
                glassimagesrc: candleData.candle[4].glassimagesrc
            })
        }
        else if(target == 'JazzButton') {
            this.setState({
                candlename: candleData.candle[5].name,
                scentfamily: candleData.candle[5].scentfamily,
                description: candleData.candle[5].description,
                ingredients: candleData.candle[5].ingredients,
                jarimagesrc: candleData.candle[5].jarimagesrc,
                glassimagesrc: candleData.candle[5].glassimagesrc
            })
        }
    }

    render() {
        return (
            <div className="CandleDisplayWrapper">
                <div id="candleimagegrid">
                    <div className="candlegridcolumn">
                    <img id="bothstylespic" className="candlegridimg" src={BothStyles}/>
                    <img id="litkintopic" className="candlegridimg" src={LitKinto}/>
                    </div>
                    <div className="candlegridcolumn">
                    <img id="kintotoppic" className="candlegridimg" src={KintoTop}/>
                    <img id="jarcandlespic" className="candlegridimg" src={JarCandles}/>
                    </div>
                </div>
                <h2 className="ProductType"> Candles </h2>
                <div id="aboutcandleproducts">
                    <p>Comes in 2 styles and sizes that would showcase beautifully anywhere in the home and the office </p>
                    <div className="contwrapper">
                    <h5>Glass Containers $37</h5>
                        <li>Recyclable heat resistant glass container and lid by the kinto brand</li>
                        <li>Includes a collectible inspirational coin at the bottom of the candle</li>
                        <li>25l ml volume</li>
                        <li>$37</li>
                    </div>
                    <div id="jarcontwrapper" className="contwrapper">
                    <h5>Jar Containers $25</h5>
                        <li>Recyclable Jar container with white metal lid</li>
                        <li>221 ml volume</li>
                        <li>$25</li>
                    </div>
                </div>
                <div className="candleButtons">
                    <p>scents to choose from </p>
                    <button onClick={this.selectCandle}><img id="LatteButton" className="candleButton" src={LatteButton} /></button>
                    <button onClick={this.selectCandle}><img id="AmazenButton" className="candleButton" src={AmazenButton} /></button>
                    <button onClick={this.selectCandle}><img id="EnchantedButton" className="candleButton" src={EnchantedButton} /></button>
                    <button onClick={this.selectCandle}><img id="FlowerDazeButton" className="candleButton" src={FlowerDazeButton} /></button>
                    <button onClick={this.selectCandle}><img id="GardenButton" className="candleButton" src={GardenButton} /></button>
                    <button onClick={this.selectCandle}><img id="JazzButton" className="candleButton" src={JazzButton} /></button>
                </div>
                <div className="candleDescription">
                    <div className="candleimages">
                        <img id="selectedJarCandleImage" src={this.state.jarimagesrc}></img>
                        <img id="selectedGlassCandleImage" src={this.state.glassimagesrc}></img>
                    </div>
                    <div id="selectedCandleDesc">
                        <h3 id="candletitle">{this.state.candlename}</h3>
                        <h4 className="productlabel">Scent Family </h4>
                        <p className="candledesc">{this.state.scentfamily}</p>
                        <h4 className="productlabel">Description </h4>
                        <p className="candledesc"> {this.state.description}</p>
                        <h4 className="productlabel">Ingredients </h4>
                        <p className="candledesc">{this.state.ingredients}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default CandleDisplay