import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios';
import './OrderInquiry.css'
import addButton from '../../pictures/assets/add.png'

class OrderInquiry extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            successful: false,
            orders: [],
            show: null,
            added: null
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.addProduct = this.addProduct.bind(this)
        this.ressetForm = this.resetForm.bind(this)
        this.unsuccessful = this.unsuccessful.bind(this)
        this.successful = this.successful.bind(this)
        //this.orderValue = this.orderValue.bind(this)
    }

    resetForm(){
        console.log('Resetting Form')
        this.setState({
            orders: "null"
        })
        document.getElementById('contact-form').reset();
    }

    successful() {
        this.setState({
            successful: true,
            show: true
        })
    }

    unsuccessful() {
        this.setState({
            successful: false,
            show: true
        })
    }
    
    /*
    orderValue(e) {
        this.setState({
            orders: document.getElementById('order').value
        })
    }*/

    addProduct(e) {
        console.log('Adding this :)')
        var value = document.getElementById('order').value
        var qty = document.getElementById('qty').value
        var part = `${qty}x${value}`
        var newOrders = this.state.orders.concat(part)
        this.setState({
            orders: newOrders,
            added: true
        })
    }

    handleSubmit(e) {
        e.preventDefault()
        const name = document.getElementById('name').value;
        const ordererEmail = document.getElementById('ordererEmail').value;
        const order = this.state.orders
        const discountCode = document.getElementById('discountCode').value;

        axios({
            method: "POST", 
            url:"https://cszlzntml3.execute-api.us-east-1.amazonaws.com/contact-order1/-contact-order", 
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                "name": name,
                "ordererEmail": ordererEmail,
                "order": order,
                "discountCode": discountCode
            }
        }).then((response)=>{
            var status = JSON.stringify(response.data.statusCode)
            console.log("Status: "+status)
            
            if (status === "200"){
                console.log('Order successfully sent')
                this.resetForm()
                this.successful()
            }else{
                console.log('Order failed to send')
                this.resetForm()
                this.unsuccessful()
            }
        }).catch((error) => {
            console.log("error: "+ error)
        })
        this.setState({
            orders: [],
            added: null
        })
    }


    //This was for contact form
    
    render() {
        console.log("show state:" + this.state.show)
        console.log("orders: " + this.state.orders)

      return (
            <div>
                <form id="contact-form" onSubmit={this.handleSubmit} method="POST">
                    <div className="form-group">
                        <label for="name">Full Name</label>
                        <input type="text" className="form-control" id="name" pattern="[a-zA-Z0-9\s]+" required/>
                    </div>
                    <div className="form-group">
                        <label for="ordererEmail">Email Address</label>
                        <input type="ordererEmail" className="form-control" id="ordererEmail" aria-describedby="emailHelp" required/>
                    </div>
                    <h1>Order</h1>
                    <div className="form-group" id="candleOptionForm">
                        <select id="candleorderselect" name="order" id="order" onChange={this.orderValue}>
                            <option value="null"> -- select an option -- </option>
                            <option value="Afternoon Latte(jar@$25)">Afternoon Latte(jar)</option>
                            <option value="Afternoon Latte(glass@$37)">Afternoon Latte(glass c.)</option>
                            <option value="Amazen(jar@$25)">AmaZen(jar)</option>
                            <option value="AmaZen(glass@$37)">AmaZen(glass c.)</option>
                            <option value="Enchanted Florist(jar@$25)">Enchanted Florist(jar)</option>
                            <option value="Enchanted Florist(glass@$37)">Enchanted Florist(glass c.)</option>
                            <option value="Flower Daze(jar@$25)">Flower Daze(jar)</option>
                            <option value="Flower Daze(glass@$37)">Flower Daze(glass c.)</option>
                            <option value="Garden Zest(jar@$25)">Garden Zest(jar)</option>
                            <option value="Garden Zest(glass@$37)">Garden Zest(glass c.)</option>
                            <option value="Jazz at the Speakeasy(jar@$25)">Jazz at the Speakeasy(jar)</option>
                            <option value="Jazz at the Speakeasy(glass@$37)">Jazz at the Speakeasy(glass c.)</option>
                            <option value="Bittersweet Tinted Lip Balm($13)">Bittersweet Tinted Lip Balm</option>
                            <option value="Coral Tinted Lip Balm($13)">Coral Tinted Lip Balm</option>
                            <option value="TeaRose Tinted Lip Balm($13)">TeaRose Tinted Lip Balm</option>
                            <option value="WineGold Tinted Lip Balm($13)">WineGold Tinted Lip Balm</option>
                        </select>
                        <input type="number" min="0" max="5" className="qty" id="qty" pattern="[0-5]" placeholder="qty" required />
                        <img className="addButton" src={addButton} onClick={this.addProduct}/>
                    </div>
                    <div className="orderList">
                        <h1>Order List: </h1>
                        <div className="orderBox">
                            {
                                this.state.added ?
                                <div className="orderList">
                                    {this.state.orders.map(function(item,key){
                                        return (
                                            <p key={item}>{item}</p>
                                        )
                                        
                                    })}
                                </div>
                                :
                                null
                            }
                        </div>

                    </div>
                    <div className="form-group">
                        <label for="discountCode">Discount Code</label>
                        <input type="discountCode" className="form-control" id="discountCode" pattern="[A-Za-z0-9]+" />
                    </div>
                    {
                        this.state.orders.length == 0 ? 
                        <button disabled="true" type="submit" className="disabledButton">Submit</button> 
                        : 
                        <button type="submit" className="abledButton">Submit</button>
                    }
                </form>
                <div>
                    {
                        this.state.show ? 
                            this.state.successful ? <h1>Your order inquiry was successfully sent!</h1> 
                            : <h1>Oh no an Error Occurred... Go ahead and email maitricompany@gmail.com directly</h1>
                        : null
                    }
                </div>
            </div>
      )
    }
}

export default OrderInquiry