import React from 'react'
import ReactDOM from 'react-dom'
import './WebsiteInfo.css'

class WebsiteInfo extends React.Component {
    render() {
      return (
        <div className="aboutsitewrapper">
            <h2>Hello my name is Sandra and i'm the developer/maintainer of this website and the owner of LiveMaitri</h2>
            <p>This site is optimized for desktop and mobile, not yet tablets...</p>
            <h2>Tech Stack:</h2>
            <li>React</li>
            <li>AWS S3</li>
            <li>AWS Lambda</li>
            <li>AWS SES</li>
            <li>AWS CloudFront</li>
        </div>
      )
    }
}

export default WebsiteInfo