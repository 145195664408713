import React from 'react'
import ReactDOM from 'react-dom'
import Slider from '../../components/Slider/Slider.js'
import PictureMarketing from '../../components/PictureMarketing/PictureMarketing.js'
import BannerPhoto1 from '../../pictures/assets/gardenz_pan2.jpg'
import CollagePortrait1 from '../../pictures/assets/flowerd_por1.JPG'
import CollagePortrait2 from '../../pictures/assets/gardenz_por3.JPG'
import LipbalmHorizontalWhite from '../../pictures/assets/lipbalm_white_horizontalcrop.jpg'
import "./HomePage.css"

class HomePage extends React.Component {
    render() {
      return (
        <div className="homePageWrapper">
          <div id="bannermessage1">
            <h3 > 25% Off Glass & Jar Container Candles</h3>
            <h5> <a href="https://www.etsy.com/in-en/shop/LiveMaitri">Shop Etsy</a> ... New Candles Coming Soon</h5>
          </div>
          <img id="bannerphoto1" src={BannerPhoto1} />
          <p id="gardencaption">Garden Zest, a tart and earthy candle...</p>
          <PictureMarketing />
          <p id="lipbalmcaption"> Nourishing Lipbalms with Manuka Honey !</p> 
          <img id="bannerphoto2" src= {LipbalmHorizontalWhite} />
          <p id="collagecaption">Check out more candles in the products page...</p>
          <div id="collagehomepage">
              <img class="collageportrait" id="firstcollageportrait"src={CollagePortrait1}/>
              <img class="collageportrait" id="secondcollageportrait" src={CollagePortrait2}/>
          </div>
        </div>
      )
    }
}

export default HomePage