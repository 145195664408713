import React from 'react'
import ReactDOM from 'react-dom';
import {Elements} from 'react-stripe-elements';
import "./OrderPage.css"
import OrderInquiry from '../../components/Form/OrderInquiry.js'
import CheckoutForm from '../../components/Form/CheckoutForm.js'
import Checkout from '../../components/Form/Checkout.js'

/*
//RENDER BELOW FOR TESTING STRIPE 
<Elements>
  <Checkout />
</Elements>
*/
class OrderPage extends React.Component {
    render() {
      return (
        <div className="orderPageWrapper">
          <h3>There are 2 ways you can order</h3>
          <div id="firstorderoption" class="orderoption">
            <h4>1</h4>
            <p>Fill out form below and click submit! It will turn green for confirmation!</p>
            <p>Within 1-3 hours, you'll recieve a paypal invoice from maitricompany@gmail.com where you can checkout</p>
            <p>Once payment is recieved, orders will be shipped out in 1-2 business days</p>
          </div>
          <div id="secondorderoption" class="orderoption">
            <h4>2</h4>
            <p>Order through <a href="https://www.etsy.com/shop/LiveMaitri">ETSY SHOP</a></p>
          </div>
          <p>Notes</p>
          <div class="noteoption">
            <p>Any questions/concerns/issues you may have, email maitricompany@gmail.com. I will make sure to get back to you within a few hours.</p>
            <p>Orders are shipped by USPS and should arrive within 3-5 business days once shipped out (Domestic Shipping Only)</p>
            <p>Shipping Cost: Flat Rate Shipping of $6.50</p>
          </div>
          <OrderInquiry />
        </div>
      )
    }
}

export default OrderPage