import React from 'react'
import ReactDom from 'react-dom'
import './Lipbalmdisplay.css'

import LipbalmBags from '../../pictures/assets/lipbalm_bags.jpg'
//BUTTONS
import CoralButton from '../../pictures/assets/coral_circle.jpg'
import TeaRoseButton from '../../pictures/assets/tea_rose_circle.jpg'
import WineGoldButton from '../../pictures/assets/wine_gold_circle.jpg'
import BittersweetButton from '../../pictures/assets/bittersweet_swatch.png'

//PRODUCTS
import CoralPicture from '../../pictures/assets/coral_swatch_clear.jpg'
import TeaRosePicture from '../../pictures/assets/tea_rose_swatch1.jpg'
import WineGoldPicture from '../../pictures/assets/wine_gold_swatch1.jpg'
import BittersweetPicture from '../../pictures/assets/bittersweet.png'

var lipbalmdata = {
    lipbalm: [
        {
            name: "Bittersweet Tinted Lip Balm - $13",
            application: "Sheer/Medium Application",
            ingredients: "KFactor16 Manuka Honey,  Extra Virgin Avocado Butter, Cacao Butter, Cera Alba (beeswax), Sweet Almond Oil, Grapeseed Oil, Peppermint Essential Oil, D-Alpha Tocopherol Vitamin E, Iron Oxide Colorant, Titanium Dioxide Colorant, Tin Dioxide Colorant, Mica",
            imagesrc: BittersweetPicture
        },
        {
            name: "Coral Tinted Lip Balm - $13",
            application: "Sheer Application",
            ingredients: "KFactor16 Manuka Honey,  Extra Virgin Avocado Butter, Cacao Butter, Cera Alba (beeswax), Sweet Almond Oil, Grapeseed Oil, Peppermint Essential Oil, D-Alpha Tocopherol Vitamin E, Iron Oxide Colorant, Titanium Dioxide Colorant, Tin Dioxide Colorant, Mica",
            imagesrc: CoralPicture
        },
        {
            name: "Tea Rose Tinted Lip Balm - $13",
            application: "Sheer/Medium Application",
            ingredients: "KFactor16 Manuka Honey,  Extra Virgin Avocado Butter, Cacao Butter, Cera Alba (beeswax), Sweet Almond Oil, Grapeseed Oil, Peppermint Essential Oil, D-Alpha Tocopherol Vitamin E, Iron Oxide Colorant, Titanium Dioxide Colorant, Tin Dioxide Colorant, Mica",
            imagesrc: TeaRosePicture
        },
        {
            name: "Wine Gold Tinted Lip Balm - $13",
            application: "Medium Application",
            ingredients: "KFactor16 Manuka Honey,  Extra Virgin Avocado Butter, Cacao Butter, Cera Alba (beeswax), Sweet Almond Oil, Grapeseed Oil, Peppermint Essential Oil, D-Alpha Tocopherol Vitamin E, Iron Oxide Colorant, Titanium Dioxide Colorant, Tin Dioxide Colorant, Mica",
            imagesrc: WineGoldPicture
        }
    ]
  };

class Lipbalmdisplay extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: lipbalmdata.lipbalm[0].name,
            application: lipbalmdata.lipbalm[0].application,
            ingredients: lipbalmdata.lipbalm[0].ingredients,
            imagesrc: lipbalmdata.lipbalm[0].imagesrc
        }
        this.selectLipbalm = this.selectLipbalm.bind(this)
    }

    componentWillMount() {
 
    }

    selectLipbalm(e) {
        var target = e.target.id
        if(target == 'BittersweetButton') {
            this.setState({
                name: lipbalmdata.lipbalm[0].name,
                application: lipbalmdata.lipbalm[0].application,
                ingredients: lipbalmdata.lipbalm[0].ingredients,
                imagesrc: lipbalmdata.lipbalm[0].imagesrc
            })
        }
        else if(target == 'CoralButton') {
            this.setState({
                name: lipbalmdata.lipbalm[1].name,
                application: lipbalmdata.lipbalm[1].application,
                ingredients: lipbalmdata.lipbalm[1].ingredients,
                imagesrc: lipbalmdata.lipbalm[1].imagesrc
            })
        }
        else if(target == 'TeaRoseButton') {
            this.setState({
                name: lipbalmdata.lipbalm[2].name,
                application: lipbalmdata.lipbalm[2].application,
                ingredients: lipbalmdata.lipbalm[2].ingredients,
                imagesrc: lipbalmdata.lipbalm[2].imagesrc
            })
        }
        else if(target == 'WineGoldButton') {
            this.setState({
                name: lipbalmdata.lipbalm[3].name,
                application: lipbalmdata.lipbalm[3].application,
                ingredients: lipbalmdata.lipbalm[3].ingredients,
                imagesrc: lipbalmdata.lipbalm[3].imagesrc
            })
        }
    }

    render() {
        return (
            <div className="LipbalmDisplayWrapper">
                <h2 className="ProductType">Lip Balms</h2>
                <img id="lipbalmbanner" src={LipbalmBags}></img>
                <div id="aboutlips">
                    <p>Maitri tinted lip balms are made of natural ingredients and use eco-friendly packaging with sturdy kraft push-up lip tubes.</p>
                    <p>The balms provide long-lasting moisture and help to prevent your delicate lips from cracking. </p>
                    <p>Balms are naturally scented from the cocoa butter and peppermint essential oil. Each lip balm is hand poured in micro-batches and made with love! Lip balms are packaged in cute little jute pouches.</p>
                </div>
                <div className="LipbalmButtons">
                    <p>tints to choose from </p>
                    <button onClick={this.selectLipbalm}><img id="BittersweetButton" className="lipBalmButton" src={BittersweetButton} /></button>
                    <button onClick={this.selectLipbalm}><img id="CoralButton" className="lipBalmButton" src={CoralButton} /></button>
                    <button onClick={this.selectLipbalm}><img id="TeaRoseButton" className="lipBalmButton" src={TeaRoseButton} /></button>
                    <button onClick={this.selectLipbalm}><img id="WineGoldButton" className="lipBalmButton" src={WineGoldButton} /></button>
                </div>
                <div className="LipbalmDescription">
                    <div className="Lipbalmimages">
                        <img id="selectedLipbalmImage" src={this.state.imagesrc}></img>
                    </div>
                    <div id="selectedLipbalmDesc">
                        <h3 id="lipname">{this.state.name}</h3>
                        <h4 className="productlabel">Description</h4>
                        <p className="lipapplication">{this.state.application}</p>
                        <h4 className="productlabel">Ingredients </h4>
                        <p className="lipingredients"> {this.state.ingredients}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Lipbalmdisplay
